import { Box, Button, ButtonGroup } from "@mui/material";
import logocompleto from '../img/logocompleto.png'

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Contactanos } from "../componentes/contactanos";

const Topbar = () => {
  const [modal, setModal] = React.useState(false);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ backgroundColor: '#141b2d80' }}>
        <Toolbar>
          <Box
            display="flex"
            borderRadius="3px"
          >
            <img
              alt="profile-user"
              width="40%"
              height="100%"
              src={logocompleto}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          </Typography>

          <ButtonGroup  variant="contained" color="secondary"  aria-label="text button group">
            <Button href="/nosotros">Nosotros</Button>
            <Button onClick={() => setModal(true)}>Contactanos</Button>
          </ButtonGroup>
        </Toolbar>
      </AppBar>
      <Contactanos modal={modal} setModal={setModal}/>
    </Box>
  );
};

export default Topbar;