import React from "react";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export const Alerta = (props) => {
    const cerrarAlerta = () => props.setAlerta(false);
    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }} open={props.alerta} autoHideDuration={2000} onClose={cerrarAlerta}>
                <Alert severity={props.tipo} sx={{ width: '100%' }}>
                    {props.mensaje}
                </Alert>
            </Snackbar>
        </Stack>
    )
}