import { React } from "react";
import { Box, Typography, Container, Link, Grid } from '@mui/material'
import recurso from '../img/Recurso.png'
const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: '#1c1d21', p: 6, color: 'white'
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h3" gutterBottom>
                            Contáctanos
                        </Typography>
                        <Typography variant="body2" >
                            Tegucigalpa, Honduras
                        </Typography>
                        <Typography variant="body2">
                            Correo: info@pgtechnologyhn.com
                        </Typography>
                        <Typography variant="body2">
                            Teléfono: +504 9785-5422
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h3" gutterBottom>
                            Siguenos
                        </Typography>
                        <div>
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/PGTechnologyhn">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span class="fa fa-facebook"></span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/pgtechnology20?igshid=MzRIODBiNWFIZA==">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span class="fa fa-instagram"></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <img
                        alt="doc"
                            width="43%"
                            src={recurso}
                            style={{ cursor: "pointer" }}
                        />
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <br />
                    <Typography variant="body2" color="white" align="center">
                        {"Copyright © "}
                        <Link color="inherit" href="http://pgtechnologyhn.com/">
                            PG TECHNOLOGY
                        </Link>{" "}
                        {new Date().getFullYear()}
                        {"."}
                    </Typography>
                </Box>
            </Container>
        </Box>
    )
}

export default Footer