import { Routes, Route } from "react-router-dom";
import Footer from "./global/Footer";
import Topbar from "./global/Topbar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Inicio from './paginas/inicio'
import Nosotros from './paginas/nosostros'
import Flotante from "./componentes/flotante";
import 'animate.css'
document.title = 'PG Technology'
function App() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content"  >
          <Topbar  />
          
            <Routes>
              <Route path='/' element={<Inicio />} exa ></Route>
              <Route path='/nosotros' element={<Nosotros />} exa ></Route>
            </Routes>
            <Footer />
            <Flotante  />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;