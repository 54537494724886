import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { WhatsApp } from '@mui/icons-material';

const style = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
};

export default function Flotante() {
    return (
      <Box sx={{ '& > :not(style)': { m: 1 } }}>
        <Fab color="secondary" aria-label="add" style={style}  href='https://api.whatsapp.com/send/?phone=50497855422'>
          <WhatsApp />
        </Fab>
      </Box>
    );
  }