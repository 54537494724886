import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import emailjs from '@emailjs/browser'
import { Box, TextField } from '@mui/material'
import { Alerta } from '../componentes/comAlertas'

export const Contactanos = (props) => {
    const [alerta, setAlerta] = React.useState(false)
    const [mensajeAlerta, setMensajeAlerta] = React.useState("");
    const [tipoAlerta, setTipoAlerta] = useState("success")
    const [nombre, setNombre] = useState("")
    const [correo, setCorreo] = useState("")
    const [mensaje, setMensaje] = useState("")
    const form = useRef();
    const handleClose = () => {
        props.setModal(false);
    };


    const sendEmail = (e) => {
        e.preventDefault();
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (nombre === "" || correo === "" || mensaje === "") {
            setMensajeAlerta("Debe llenar todos los campos")
            setTipoAlerta("error")
            setAlerta(true)
        } else {

            if (!emailRegex.test(correo)) {
                console.log('Ingrese un correo valido');
            } else {
                emailjs.sendForm('service_demtphn', 'template_9ko15v6', form.current, 'UAaQ3hrOO4rbJq1dJ')
                    .then((result) => {
                        console.log(result.text);
                        setMensajeAlerta("Su mensaje ha sido enviado")
                        setTipoAlerta("success")
                        setAlerta(true)
                        handleClose()
                    }, (error) => {
                        console.log(error.text);
                    });
            }
        }
    };

    return (
        <>
            <Dialog
                open={props.modal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "500px",  // Set your width here
                        },
                    },
                }}
            >
                <form ref={form} onSubmit={sendEmail}>
                    <DialogContent>

                        <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center" minHeight="0vh" >
                            <Box sx={{ gridColumn: "span 12" }} textAlign="center" >
                                <h1>Contáctanos</h1>
                            </Box>
                        </Box>
                        <Box m="30px 0px 30px 0px" display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center" minHeight="0vh" >
                            <Box sx={{ gridColumn: "span 12" }} >
                                <TextField id="outlined-basic" label="Nombre" name='user_name' onChange={(e) => setNombre(e.target.value)} fullWidth variant="outlined" />
                            </Box>
                        </Box>
                        <Box m="30px 0px 30px 0px" display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center" minHeight="0vh" >
                            <Box sx={{ gridColumn: "span 12" }} >
                                <TextField id="outlined-basic" type='email' name='user_mail' onChange={(e) => setCorreo(e.target.value)} label="Correo electronico" fullWidth variant="outlined" />
                            </Box>
                        </Box>
                        <Box m="30px 0px 30px 0px" display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center" minHeight="0vh" >
                            <Box sx={{ gridColumn: "span 12" }} >
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Mensaje"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    name='message'
                                    onChange={(e) => setMensaje(e.target.value)}
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit'>Enviar</Button>
                        <Button onClick={handleClose} autoFocus>
                            Cancelar
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Alerta alerta={alerta} setAlerta={setAlerta} mensaje={mensajeAlerta} tipo={tipoAlerta} />
        </>
    )
} 