import React from 'react'
import { Box} from '@mui/material'
import fondo2 from '../img/fondo2.png'
import fondo3 from '../img/fondo3.png'
import fondo4 from '../img/fondo4.png'
import nosotros from '../img/nosotros.mp4'
import quienes from '../img/quienes.png'
import mision from '../img/mision.png'
import vision from '../img/vision.png'
export const Nosotros = () => {
    return (
        <>
            <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" sx={{ borderRadius: '150px 150px 150px 150px' }} alignItems="center" minHeight="0vh" >
                <Box sx={{ gridColumn: "span 12" }} >
                    <Box display="flex" justifyContent="center" alignItems="center" >
                        <div className='video-container'>
                            <video style={{ objectFit: 'cover', width: '100%', height: '100%' }} playsInline src={nosotros} autoPlay loop muted className="fillWidth" />
                        </div>
                    </Box>
                </Box>
            </Box>
            <Box m="0px 0px 30px 0px" display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center" minHeight="0vh">
                <Box display="flex" alignItems="center" sx={{ gridColumn: { md: "span 6", xs: "span 12" }, justifyContent: { md: "center", xs: "center" }, backgroundImage: `url(${fondo2})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}   >
                    <table className="prueba">
                        <tr>
                            <td align="center">
                                <h1>¿Quienes Somos?</h1>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p style={{ textAlign: "justify", fontSize: 'large' }}>Nuestro equipo está compuesto por profesionales apasionados y altamente cualificados en desarrollo web, programación, diseño gráfico. Cada miembro de nuestro equipo aporta un conjunto único de habilidades y experiencia para garantizar que todos nuestros proyectos sean un éxito. Nuestros clientes son nuestra prioridad número uno, trabajamos en colaboración con ustedes desde el primer contacto hasta la entrega final para asegurarnos de que sus expectativas se cumplan y superen.</p>
                            </td>
                        </tr>
                    </table>
                </Box>
                <Box sx={{ gridColumn: { md: "span 6", xs: "span 12" } }} >
                    <Box display="flex" justifyContent="center" alignItems="center" >
                        <img
                            alt="profile-user"
                            width="43%"
                            src={quienes}
                            style={{ cursor: "pointer" }}
                        />
                    </Box>
                </Box>
            </Box>
            <hr />
            <Box m="0px 0px 30px 0px" display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center" minHeight="0vh">
                <Box sx={{ gridColumn: { md: "span 6", xs: "span 12" } }} >
                    <Box display="flex" justifyContent="center" alignItems="center" >
                        <img
                            alt="profile-user"
                            width="43%"
                            src={mision}
                            style={{ cursor: "pointer" }}
                        />
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" sx={{ gridColumn: { md: "span 6", xs: "span 12" }, justifyContent: { md: "center", xs: "center" }, backgroundImage: `url(${fondo3})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain' }}   >
                    <table className="prueba">
                        <tr>
                            <td align="center">
                                <h1>Nuestra Misión</h1>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p style={{ textAlign: "justify", fontSize: 'large' }}>Brindar soluciones digitales de alta calidad que impulsen el éxito de nuestros clientes. Nos dedicamos apasionadamente al desarrollo de páginas web y sistemas web, trabajando de la mano con nuestros clientes para transformar sus ideas en realidades digitales. Nos esforzamos por ofrecer productos y servicios que sean intuitivos, seguros y atractivos, permitiendo a nuestros clientes destacar en un mundo cada vez más digital.</p>
                            </td>
                        </tr>
                    </table>
                </Box>
            </Box>
            <hr />
            <Box m="0px 0px 30px 0px" display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center" minHeight="0vh">
                <Box display="flex" alignItems="center" sx={{ gridColumn: { md: "span 6", xs: "span 12" }, justifyContent: { md: "center", xs: "center" }, backgroundImage: `url(${fondo4})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain' }}   >
                    <table className="prueba">
                        <tr>
                            <td align="center">
                                <h1>Nuestra Visión</h1>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p style={{ textAlign: "justify", fontSize: 'large' }}>Buscamos ser la primera elección de empresas y emprendedores que deseen aprovechar al máximo las posibilidades del mundo digital. Planeamos lograrlo mediante la innovación constante, la inversión en talento humano y la adopción de tecnologías de vanguardia. Queremos ser conocidos por nuestro compromiso con la excelencia, la satisfacción del cliente y la contribución al crecimiento sostenible de nuestros socios comerciales.</p>
                            </td>
                        </tr>
                    </table>
                </Box>
                <Box sx={{ gridColumn: { md: "span 6", xs: "span 12" } }} >
                    <Box display="flex" justifyContent="center" alignItems="center" >
                        <img
                            alt="profile-user"
                            width="43%"
                            src={vision}
                            style={{ cursor: "pointer" }}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    )
};


export default Nosotros