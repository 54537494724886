import { useState, React } from "react";
import { Box, Typography, Avatar, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, CardContent, Container, Link, Grid, Card, CardMedia, CardActionArea, paginationItemClasses } from '@mui/material'
import prueba from '../img/prueba2.mp4'
import motivacion from '../img/motivacion.png'
import mision from '../img/mision.gif'
import nueva1 from '../img/1.png'
import nueva2 from '../img/2.png'
import nueva3 from '../img/3.png'
import nueva4 from '../img/4.png'
import nueva5 from '../img/5.png'
import nueva6 from '../img/6.png'
import html from '../img/html.png'
import css from '../img/css.png'
import javascript from '../img/javascript.png'
import react from '../img/react.png'
import sql from '../img/sql server.png'
import mysql from '../img/mysql.png'
import nodejs from '../img/node js.png'
import asp from '../img/asp.png'
import php from '../img/php.png'
import photoshop from '../img/photoshop.png'
import ilustrador from '../img/ilustrador.png'
import affter from '../img/affter.png'
import premier from '../img/premier.png'
import 'animate.css'
import './estilo.css'
<script src="https://kit.fontawesome.com/1c2c2462bf.js" crossorigin="anonymous"></script>


const Inicio = () => {
    return (
        <div >
            <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" sx={{ borderRadius: '150px 150px 150px 150px' }} alignItems="center" minHeight="0vh" >
                <Box sx={{ gridColumn: "span 12" }} >
                    <Box display="flex" justifyContent="center" alignItems="center" >
                        <div className='video-container'>
                            <video style={{ objectFit: 'cover', width: '100%', height: '100%' }} playsInline src={prueba} autoPlay loop muted className="fillWidth" />
                        </div>
                    </Box>
                </Box>
            </Box>
            <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center" minHeight="35vh" >
                <Box sx={{ gridColumn: "span 12" }} >
                    <div class="contenedor">
                        <div class="faders">
                            <div class="left"></div>
                            <div class="right"></div>
                        </div>
                        <div class="items">
                            <div class="entry">
                                <img
                                    alt="profile-user"
                                    width="60%"
                                    src={html}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <div class="entry">
                                <img
                                    alt="profile-user"
                                    width="43%"
                                    src={css}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <div class="entry">
                                <img
                                    alt="profile-user"
                                    width="60%"
                                    src={javascript}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <div class="entry">
                                <img
                                    alt="profile-user"
                                    width="60%"
                                    src={react}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <div class="entry">
                                <img
                                    alt="profile-user"
                                    width="70%"
                                    src={nodejs}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <div class="entry">
                                <img
                                    alt="profile-user"
                                    width="70%"
                                    src={asp}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <div class="entry">
                                <img
                                    alt="profile-user"
                                    width="50%"
                                    src={php}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <div class="entry">
                                <img
                                    alt="profile-user"
                                    width="50%"
                                    src={sql}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <div class="entry">
                                <img
                                    alt="profile-user"
                                    width="50%"
                                    src={mysql}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
            <Container maxWidth="fixed">
                <hr />
                <Box m="30px 0px 30px 0px" display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center" minHeight="35vh">
                    <Box display="flex" alignItems="center" sx={{ gridColumn: { md: "span 4", xs: "span 12" }, justifyContent: { md: "center", xs: "center" } }}   >
                        <Card sx={{
                            ':hover': {
                                boxShadow: 20, // theme.shadows[20]
                            }, color: 'black'
                        }}>
                            <CardActionArea >
                                <CardContent>
                                    <table className="animate__animated animate__fadeInLeft">
                                        <tr>
                                            <td align="center">
                                                <h1><span><u>Paginas Web</u></span></h1>
                                            </td>
                                        </tr>
                                        <tr >
                                            <td align="center"><img
                                                alt="profile-user"
                                                width="60%"
                                                src={nueva1}
                                                style={{ cursor: "pointer" }}

                                            /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span><b>Una herramienta esencial para promocionar productos, servicios o información en línea y atraer al público.</b></span>
                                            </td>
                                        </tr>
                                    </table>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                    <Box display="flex" alignItems="center" sx={{ gridColumn: { md: "span 4", xs: "span 12" }, justifyContent: { md: "center", xs: "left" } }} >
                        <Card sx={{
                            ':hover': {
                                boxShadow: 20, // theme.shadows[20]
                            }, color: 'black'
                        }}>
                            <CardActionArea >
                                <CardContent>
                                    <table className="animate__animated animate__fadeInDown">
                                        <tr>
                                            <td align="center">
                                                <h1> <span><u>Sistemas Automatizados</u></span></h1>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="center">
                                                <img
                                                    alt="profile-user"
                                                    width="60%"
                                                    src={nueva2}
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <span><b>No solo nos permite ahorrar tiempo, sino que también puede mejorar la eficacia de las estrategiasen la empresa. </b></span>
                                            </td>
                                        </tr>
                                    </table>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                    <Box display="flex" alignItems="center" sx={{ gridColumn: { md: "span 4", xs: "span 12" }, justifyContent: { md: "center", xs: "left" } }} >

                        <Card sx={{
                            ':hover': {
                                boxShadow: 20, // theme.shadows[20]
                            }, color: 'black'
                        }}>
                            <CardActionArea>
                                <CardContent>
                                    <table className="animate__animated animate__fadeInRight">
                                        <tr>
                                            <td align="center">
                                                <h1><span><u>Diseño Gráfico</u></span></h1>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="center">
                                                <img
                                                    alt="profile-user"
                                                    width="60%"
                                                    src={nueva3}
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span><b>El diseño gráfico es más que colores y formas; es la esencia de tu marca. Permítenos capturar esa esencia para ti.  </b></span>
                                            </td>
                                        </tr>
                                    </table>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Box>
            </Container>
            <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center" minHeight="35vh" >
                <Box sx={{ gridColumn: "span 12" }} >
                    <div class="contenedor">
                        <div class="faders">
                            <div class="left"></div>
                            <div class="right"></div>
                        </div>
                        <div class="items">
                            <div class="entry">
                                <img
                                    alt="profile-user"
                                    width="40%"
                                    src={photoshop}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <div class="entry">
                                <img
                                    alt="profile-user"
                                    width="40%"
                                    src={ilustrador}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <div class="entry">
                                <img
                                    alt="profile-user"
                                    width="40%"
                                    src={affter}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <div class="entry">
                                <img
                                    alt="profile-user"
                                    width="40%"
                                    src={premier}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
            <Container maxWidth="fixed">
                <hr />
                <Box m="30px 0px 30px 0px" display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center" minHeight="30vh">
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ gridColumn: { md: "span 4", xs: "span 12" } }}   >
                        <div class="testimotionals">
                            <div class="card">
                                <div class="layer"></div>
                                <div class="content">
                                    <p style={{ textAlign: "justify" }}>Creamos sitios web atractivos y sistemas web funcionales que reflejan la identidad única de cada cliente y generan una impresión memorable.</p>
                                    <div class="image">
                                        <img
                                            alt="profile-user"
                                            width="100%"
                                            src={nueva4}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                    <div class="details">
                                        <h1> <span>Creatividad y Diseño Innovador</span></h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ gridColumn: { md: "span 4", xs: "span 12" } }} >
                        <div class="testimotionals">
                            <div class="card">
                                <div class="layer"></div>
                                <div class="content">
                                    <p style={{ textAlign: "justify" }}>Utilizamos las últimas tecnologías y herramientas de desarrollo web para garantizar que los proyectos estén a la vanguardia de la innovación tecnológica. <br /></p>
                                    <div class="image">
                                        <img
                                            alt="profile-user"
                                            width="100%"
                                            src={nueva5}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                    <div class="details">
                                        <h1> <span>Tecnología Avanzada</span></h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ gridColumn: { md: "span 4", xs: "span 12" } }} >
                        <div class="testimotionals">
                            <div class="card">
                                <div class="layer"></div>
                                <div class="content">
                                    <p style={{ textAlign: "justify" }}> Tenemos estrecha colaboración con nuestros clientes para comprender sus necesidades específicas y ofrecer soluciones web personalizadas..<br /></p>
                                    <div class="image">
                                        <img
                                            alt="profile-user"
                                            width="100%"
                                            src={nueva6}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                    <div class="details">
                                        <h1> <span>Colaboración y Personalización</span></h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Container>
        </div>
    )
}

export default Inicio